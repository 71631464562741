
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Input, Slider, Space, Spin, Typography } from 'antd';
import Ai_logo from "../../assets/ai_icon.png";
import LogoutIcon from "../../assets/Group.png"
import useAxios from '../../hooks/useAxios';
import { useLogoutMutation } from '../../global/globalApi';
import { batch, useDispatch, useSelector } from 'react-redux';
import { StateTypes } from '../../model/data';
import { useHistory } from 'react-router-dom';
import { clearDeckState, clearUser, queryActivePage, setLaModalVisible } from '../../model';
import { setIsTermAndConditionVisible } from '../../model/dashboard';
import SelectLa from '../common/SelectLa';
import { errorPrompt } from '../common/Prompt';
import { ProjectNetworkLayer } from '../layers/layerConfigs/ProjectNetworkLayer';
import useProjectStore, { ProjectStateTypes } from '../../stores/ProjectStore';
import { useEffect, useState } from 'react';


const MapDataExplorerSection = ({ step, pins, setStep, setLoading, inputValue, setInputValue, setCostResults }: any) => {
    const {
        setProjectNetworkLayer,
    } = useProjectStore((state: ProjectStateTypes) => ({
        setProjectNetworkLayer: state.setProjectNetworkLayer,
    }));
    const [datasetsInfo, setDatasetsInfo] = useState<any>([]);
    const [datasetLoading, setDatasetLaoding] = useState<boolean>(false)

    const { Text, Title } = Typography
    const axios = useAxios();
    const { mutate } = useLogoutMutation(axios);
    const dispatch = useDispatch();
    const history = useHistory();
    const region = JSON.parse(window.sessionStorage.getItem("local_authority") ?? "{}");
    const dno_name = `${process.env.REACT_APP_DNO_NAME}`;
    const la = JSON.parse(window.sessionStorage.getItem("local_authority") ?? "{}");

    const access_token = useSelector(
        (state: StateTypes) => state.user.currentUser
    );

    const onChange = (newValue: number) => {
        setInputValue(newValue)
    };

    const calculateCostResults = async () => {
        if (Number(inputValue) < 0.5) {
            errorPrompt({ message: "This tool is designed to provide connection costings for connections over 0.5MVA. Please enter a higher peak load." })
            return;
        }
        try {
            setLoading(true)
            setStep(3);
            const payload = {
                "point": {
                    "longitude": pins[0]?.coordinates[0],
                    "latitude": pins[0]?.coordinates[1]
                },
                "load": Number(inputValue)
            }
            const { data } = await axios.post(
                `${process.env.REACT_APP_BASE_URL_2}/cco/${dno_name}/la/${la.name}/nearestcable/`,
                payload
            );
            setCostResults(data?.data?.[0])
            setStep(4);
        } catch (error) {
            errorPrompt({ message: "Error in calculating cost estimation." })
        } finally {
            setLoading(false)

        }
    }

    const signout = async () => {
        const payload = {
            access_token: access_token?.accessToken ? access_token?.accessToken : "",
        };
        mutate(payload, {
            onSuccess: () => {
                batch(() => {
                    dispatch(clearUser());
                    dispatch(clearDeckState());
                    dispatch(queryActivePage({ pageName: "/", index: 0 }));
                });
                window.Intercom("shutdown");
                window.sessionStorage.removeItem("currentUser");
                window.sessionStorage.removeItem("local_authority");
                window.sessionStorage.removeItem("previousPageUrl")
                history.push("/");
            },
        });
    };

    const handleDisplayLayer = () => {
        const updatedDatasetsInfo = datasetsInfo.map((dataset: any) => ({
            ...dataset,
            default: dataset.table_name === "drg_substation_areas" ? !dataset.default : false
        }))
        setDatasetsInfo(updatedDatasetsInfo);
    }

    const getDatasetListInfo = async () => {
        try {
            setDatasetLaoding(true)
            const { data: { data: datasetsInfo } } = await axios.get(`${process.env.REACT_APP_BASE_URL}/ls/${dno_name}/la/${la.name}/networkdata/`, {
            });
            setDatasetsInfo(datasetsInfo?.category_data?.slice(0, 1));
        } catch (error) {
            console.log(error)
        } finally {
            setDatasetLaoding(false)
        }
    }

    useEffect(() => {
        getDatasetListInfo();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const dataset: any = datasetsInfo?.find((data: any) => data.table_name === "drg_substation_areas")
        if (dataset) {
            const obj = ProjectNetworkLayer(dataset, dataset.default, undefined, inputValue)
            setProjectNetworkLayer(obj)
        }
        // eslint-disable-next-line
    }, [datasetsInfo])

    useEffect(() => {
        const selectedLayer: any = datasetsInfo?.find((data: any) => data.default)
        if (inputValue) {
            const updatedNetworkLayer = ProjectNetworkLayer(selectedLayer, true, undefined, inputValue)
            setProjectNetworkLayer(updatedNetworkLayer)
        }
        // eslint-disable-next-line
    }, [inputValue])


    return (
        <>
            <Space direction="vertical" className='data_explorer'>
                <Title level={5} style={{ lineHeight: 0.8 }}> Assess spare capacity</Title>
                <Divider style={{ margin: 0, padding: 0 }} />
                <Text >Set the size of the capacity to be connected. This can be a single asset or the total capacity of a project.</Text>
                <Text>This tool runs a quick assessment of the network capacity available to the specified connection. This can help flag areas with available headroom and areas which are relatively constrained.</Text>
                <Space style={{ padding: 10, borderRadius: 8, marginTop: 6, width: "100%", background: "#F0F2F5", display: "flex", alignItems: "flex-start" }}>
                    <InfoCircleOutlined />
                    <Text>Substation data is showing demand headroom</Text>
                </Space>
                <Space style={{ width: "100%", gap: 25 }}>
                    <Slider className="data_explorer_custom_slider"
                        trackStyle={{
                            backgroundColor: "#E6E6E6",
                        }}
                        dots={false}
                        step={0.1}
                        included={true}
                        marks={{
                            0: { label: <div style={{ fontSize: 10, fontWeight: 500 }}><span>0</span><p>MVA</p></div>, style: { marginLeft: 0 } },
                            3: { label: <div style={{ fontSize: 10, fontWeight: 500 }}><span>3</span><p>MVA</p></div>, style: { marginLeft: -5 } },
                            7: { label: <div style={{ fontSize: 10, fontWeight: 500 }}><span>7</span><p>MVA</p></div>, style: { marginLeft: -5 } },
                            10: { label: <div style={{ fontSize: 10, fontWeight: 500 }}><span>10</span><p>MVA</p></div>, style: { marginLeft: -10 } },
                        }}
                        handleStyle={{
                            backgroundColor: "#44946C",
                            borderColor: "#44946C",
                        }} min={0} max={10} onChange={onChange} value={inputValue as number} />
                    <Input
                        min={0}
                        max={10}
                        placeholder='MVA'
                        value={inputValue}
                        style={{ borderRadius: 6 }}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (/^(|[1-9](\.\d{0,2})?)$/.test(value)) {
                                setInputValue(value);
                            }
                        }}
                    />
                </Space>
                <Space className="data_explorer_datasets">
                    {datasetLoading ? (
                        <Spin style={{ width: "100%" }} />
                    ) :
                        (
                            datasetsInfo?.map((dataset: any) => (
                                <Checkbox
                                    key={dataset?.type || Math.random()}
                                    style={{ fontSize: 12, fontWeight: 600 }}
                                    onChange={handleDisplayLayer}
                                    checked={dataset.default}
                                >
                                    {dataset?.type}
                                </Checkbox>
                            ))
                        )
                    }
                </Space>
                <Button type="primary" disabled={step < 2} style={{ border: "none", borderRadius: 8, background: "#44946C", width: "100%" }} onClick={calculateCostResults} > Estimate connection cost</Button>
                <div className='data_explorer_footer'>
                    <div
                        style={{
                            display: "flex",
                            border: "1px solid #2C2C2C33",
                            borderRadius: 8,
                            padding: 10,
                            width: "100%",
                            gap: 10
                        }}
                    >
                        <Button
                            style={{
                                borderRadius: 6,
                                flex: 1,
                                background: "#E3E3E3",
                                flexShrink: 0,
                                border: "1px solid #767676",
                                height: 30,
                                fontSize: 12,
                                fontWeight: 600
                            }}
                            onClick={() => dispatch(setLaModalVisible(true))}
                        >
                            {region.description}
                        </Button>
                        <Button
                            style={{
                                borderRadius: 6,
                                border: "1px solid red",
                                color: "red",
                                background: "#C843004D",
                                width: 90,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                height: 30,
                                fontSize: 12,
                                fontWeight: 600
                            }}
                            icon={<img style={{ width: 12, height: 12, marginRight: 4 }} color='white' src={LogoutIcon} alt='icon' />}
                            onClick={signout}
                        >
                            Logout
                        </Button>
                    </div>
                    <Space direction="horizontal" style={{ display: "flex", justifyContent: "space-between" }}>
                        <span><img src={Ai_logo} style={{ height: 16, width: "auto", paddingLeft: 10 }} alt="ailogo" /></span>
                        <span style={{ fontWeight: 600, textDecoration: "underline", textUnderlineOffset: 2, cursor: "pointer" }} onClick={() => dispatch(setIsTermAndConditionVisible(true))}>Terms and Conditions</span>
                    </Space>
                </div>
            </Space >
            <SelectLa />
        </>

    );
};

export default MapDataExplorerSection;