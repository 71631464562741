import { useEffect, useState } from "react";
import { IconLayer } from "deck.gl";
import MapPin from "../../assets/Map_pin.png";

import Deck from "../common/Deck";
import MapDataExplorerSection from "./MapDataExplorerSection";
import NotificationSection from "./NotificationSection";
import TermsAndConditions from "../../pages/TermsAndConditions";
import { isEmpty } from "lodash";
import { FlyToInterpolator } from "react-map-gl";

const la = JSON.parse(
    window.sessionStorage.getItem("local_authority") ?? "{}"
);

const initialViewState = {
    latitude: la?.latitude,
    longitude: la?.longitude,
    zoom: 6.5,
    minZoom: 2,
    maxZoom: 24,
    bearing: 0,
    pitch: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
};

export const ESBConnectionUI = () => {
    const [pins, setPins] = useState<any[]>([]);
    const [step, setStep] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>("");
    const [costResults, setCostResults] = useState<any>({})

    useEffect(() => {
        if (inputValue && isEmpty(pins)) {
            setStep(1);
        } else if (inputValue && !isEmpty(pins)) {
            setStep(2);
        }
    }, [inputValue, pins]);

    const handleIconClick = (info: any) => {
        if (info && info.object) {
            setStep((prevStep) => prevStep + 1);
        }
    };

    const pinLayer = new IconLayer({
        id: "icon-layer",
        data: pins,
        pickable: true,
        iconAtlas: MapPin,
        iconMapping: {
            marker: { x: 0, y: 0, width: 88, height: 80, mask: false },
        },
        getIcon: () => "marker",
        getPosition: (d) => d.coordinates,
        getSize: 40,
        getColor: [0, 0, 0],
        onClick: handleIconClick,
    });

    if (loading) {
        return <NotificationSection step={step} />;
    }

    return (
        <div>
            <MapDataExplorerSection
                step={step}
                setStep={setStep}
                pins={pins}
                loading={loading}
                setLoading={setLoading}
                inputValue={inputValue}
                setInputValue={setInputValue}
                setCostResults={setCostResults}
            />
            <Deck
                pins={pins}
                setPins={setPins}
                step={step}
                customLayers={pinLayer}
                viewStateProp={initialViewState}
                results={costResults}
            />
            <NotificationSection step={step} setStep={setStep} setPins={setPins} results={costResults} />
            <TermsAndConditions />
        </div>
    );
};

export default ESBConnectionUI;
