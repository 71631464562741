import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Typography } from "antd";
import { isEmpty } from "lodash";

const notificationStatus = ["Choose MVA quantity", "Place the pin on the map", "Click on “Estimate connection cost", "Estimating connection cost...", "Results"]


const NotificationSection = ({ step, setStep, setPins, results }: any) => {
    const isResultUI = step === 4
    const formatCurrency = (value: any) => {
        return `€${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    };
    return (
        <div className='esb_notification' style={{ textAlign: isResultUI ? "start" : "center" }}>
            <span style={{ fontWeight: 600, cursor: isResultUI ? "pointer" : "none", textDecoration: isResultUI ? "underline" : "none" }}>{notificationStatus[step]}</span>

            {isResultUI && !isEmpty(results) && <div>
                <Divider style={{ margin: "10px 0" }} />
                <div className="esb_notification_resultbox" style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }} >
                    <div ><span>Distance to nearest point (km) <Typography.Title level={5}>{results.distance_to_nearest_point_in_km}</Typography.Title></span></div>
                    <div><span>Installation cost<Typography.Title level={5}>{results.installation_cost}</Typography.Title></span></div>
                    <div><span>Per mva cost<Typography.Title level={5}>{results.per_mva_cost}</Typography.Title></span></div>
                    <div><span>Supplementary cost<Typography.Title level={5}>{results.supplementary_cost}</Typography.Title></span></div>
                    <div><span>Total cost<Typography.Title level={5}>{formatCurrency(results.total_cost)}</Typography.Title></span></div>
                </div>
                <strong>Lorem ipsum</strong>
                <Divider style={{ margin: "10px 0" }} />
                <div className="submitRequest">
                    <span><InfoCircleOutlined /></span>
                    <div>
                        <p>Placeholder- disclaimer message to be added here</p>
                        <a href="https://www.esbnetworks.ie/new-connections/types-of-new-connections"
                            target="_blank"
                            rel="noopener noreferrer"> <Button type="primary" style={{ background: "#E3E3E3", color: "var(--default-black)", borderRadius: 8, border: "1px solid #767676" }}>Submit formal request</Button></a>
                    </div>
                </div>
                <Button onClick={() => { setStep(1); setPins([]); }} style={{ border: "none", color: "white", borderRadius: 8, background: "#44946C", width: "100%" }}>Start again</Button>
            </div>}
        </div >)
};

export default NotificationSection;